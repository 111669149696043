import React from "react"
import styled from "styled-components"

import { Container } from "../global"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import Badge from '@material-ui/core/Badge';

import AssignmentIcon from '@material-ui/icons/Assignment';
import BarChartIcon from '@material-ui/icons/BarChart';
import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import RoomIcon from '@material-ui/icons/Room';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
const Features = () => (
  <ExtendedFeatureWrapper id="extended-features">
    <Container>
      <Flex>
        <SectionTitle>

          <FormattedMessage id="extended_features_headline" defaultMessage="Smart tool management" />
        </SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <Badge badgeContent={"Beta"} color="error"><LocalShippingIcon fontSize="large" /></Badge>
            <FeatureTitle>
              <FormattedMessage id="extended_features_delivery_title" defaultMessage="Delivery" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_delivery_text" defaultMessage="Forgot a tool? No problem, schedule a delivery and our distrubution partners will deliver the missing tool." />
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <Badge badgeContent={"Beta"} color="error"><BuildIcon fontSize="large" /></Badge>
            <FeatureTitle>
              <FormattedMessage id="extended_features_repairs_title" defaultMessage="Repairs" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_repairs_text" defaultMessage="Keep track of recurring maintenance and schedule pickup and delivery straight to a repair center in our app." />
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <RoomIcon fontSize="large" />
            <FeatureTitle>
              <FormattedMessage id="extended_features_tracking_title" defaultMessage="Tracking" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_tracking_text" defaultMessage="Track the usage of each tool, who used it and when. Always know how has which power tool, never look for a tool again." />
            </FeatureText>
          </FeatureItem>
          {/* <FeatureItem>
            <VerifiedUserIcon fontSize="large" />
            <FeatureTitle>
              <FormattedMessage id="extended_features_deductible_waiver_title" defaultMessage="Deductable waiver" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_deductible_waiver_text" defaultMessage="Deductable waiver for all your tools that are added to the system, when your main insurance has paid out your money, we will reimburse the deductible." />
            </FeatureText>
          </FeatureItem> */}
          <FeatureItem>
            <Badge badgeContent={"Beta"} color="error"><EventAvailableIcon fontSize="large" /></Badge>
            <FeatureTitle>
              <FormattedMessage id="extended_features_tool_rental_title" defaultMessage="Tool rental" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_tool_rental_text" defaultMessage="Tool rental in the tool. Search and rent tools in the app. Get it delivered immediately to your job site." />
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <BarChartIcon fontSize="large" />
            <FeatureTitle>
              <FormattedMessage id="extended_features_analytics_reporting_title" defaultMessage="Analytics & reporting" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_analytics_reporting_text" defaultMessage="Analytics on usage broken down per project and user. Costs and loss analytics and pdf and email reporting." />
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <AssignmentIcon fontSize="large" />
            <FeatureTitle>
              <FormattedMessage id="extended_features_tool_inventory_title" defaultMessage="Tool inventory" />
            </FeatureTitle>
            <FeatureText>
              <FormattedMessage id="extended_features_tool_inventory_text" defaultMessage="All your tools in one place. All documentation, invoices and reciepts and information collected in one place." />
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>
      </Flex>
    </Container>
  </ExtendedFeatureWrapper >
)

export default Features

const StyledContainer = styled(Container)``


const ExtendedFeatureWrapper = styled.header`
  background-color: #F9FAFB;
  padding: 60px 20px 40px 20px;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`

const MaterialIcons = styled.span`
    font-family: 'Material Icons';
    font-size: 24px;
`