import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const ProductLeft = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "person_screen_interaction" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <ProductLeftWrapper id="product">
      <Container>
        <Flex>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
          <HeaderTextGroup>
            <h1>
              <FormattedMessage id="product_left_headline_1" defaultMessage="Always know the status of" />
              {/*<br />
              <FormattedMessage id="product_left_headline_2" defaultMessage="all your equipment and tools" />*/}
            </h1>
            <h2>
              <FormattedMessage id="product_left_text" defaultMessage="Add all your tools using QR stickers with integrated NFC to make it easy for any of your employees to checkout and return tools whenever they need to." />
            </h2>
          </HeaderTextGroup>
        </Flex>
      </Container>
    </ProductLeftWrapper>
  )
}

export default ProductLeft

const ProductLeftWrapper = styled.header`
  background-color: #F9FAFB;
  padding: 100px 0 40px 0;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 60px 20px 40px 20px;

  }
`



const HeaderTextGroup = styled.div`
  margin: 0;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  padding-right: 20px;
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 440px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
