import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import "rc-slider/assets/index.css"

import { Container } from "../global"

const Pricing = () => {
  const signupHref = "https://app.buildsight.se/signup"
  const contactEmail = "mailto:info@buildsight.se"

  const priceColumns = [
    {
      title: "pricing_plan_small_title",
      price: {
        price: "pricing_plan_small_price",
        text: "pricing_plan_small_price_modifier",
      },
      info: [
        "pricing_plan_small_feature_1",
        "pricing_plan_small_feature_2",
        "pricing_plan_small_feature_3",
        "pricing_plan_small_feature_4",
      ],
      button: {
        text: "pricing_plan_small_get_started",
        href: signupHref,
      },
    },
    {
      title: "pricing_plan_medium_title",
      price: {
        price: "pricing_plan_medium_price",
        text: "pricing_plan_medium_price_modifier",
      },
      info: [
        "pricing_plan_medium_feature_1",
        "pricing_plan_medium_feature_2",
        "pricing_plan_medium_feature_3",
        "pricing_plan_medium_feature_4",
      ],
      button: {
        text: "pricing_plan_medium_get_started",
        href: signupHref,
      },
    },
    {
      title: "pricing_plan_large_title",
      price: {
        price: "pricing_plan_large_price",
        text: " ",
      },
      info: [
        "pricing_plan_large_feature_1",
        "pricing_plan_large_feature_2",
        "pricing_plan_large_feature_3",
        "pricing_plan_large_feature_4",
      ],
      button: {
        text: "pricing_plan_large_get_started",
        href: contactEmail,
      },
    },
  ]

  return (
    <PricingWrapper id="pricing">
      <Container>
        <PricingTitle>
          <FormattedMessage
            id="pricing_headline"
            defaultMessage="Value for every company"
          />
        </PricingTitle>
        <Flex>
          {priceColumns.map(({ title, price, info, button }) => {
            return (
              <PriceList key={title}>
                <PriceHeader>
                  <PriceModifier>
                    <span style={{ fontSize: "22px", fontWeight: "700" }}>
                      <FormattedMessage id={price.price} />
                    </span>
                    <FormattedMessage id={price.text} />
                  </PriceModifier>
                </PriceHeader>
                <PriceHeader>
                  <FormattedMessage id={title} />
                </PriceHeader>
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                {info.map((row, index) => {
                  return (
                    <ListItem key={`listItem-${index}`}>
                      <FontAwesomeIcon
                        style={{ color: "#EACC47" }}
                        icon={faCheckCircle}
                        size="1x"
                      />
                      <span style={{ paddingLeft: "16px" }}>
                        <FormattedMessage id={row} />
                      </span>
                    </ListItem>
                  )
                })}
                <ButtonItem>
                  <TryItButton
                    onClick={e => {
                      e.preventDefault()
                      window.location.href = button.href
                    }}
                  >
                    <FormattedMessage id={button.text} />
                  </TryItButton>
                </ButtonItem>
              </PriceList>
            )
          })}
        </Flex>
      </Container>
    </PricingWrapper>
  )
}

export default Pricing

const PricingWrapper = styled.header`
  background-color: #f9fafb;
  padding: 80px 120px 40px 120px;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 80px 0 40px 0;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-gap: 2rem;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const Divider = styled.div`
  border-top: 1px solid #eee;
  padding: 0px;
`

const PriceList = styled.div`
  list-style-type: none;
  border: 1px solid #eee;
  border-radius: 15px;
  margin: 10px 0;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: ${props => props.theme.color.white.regular};
  @media (max-width: ${props => props.theme.screen.md}) {
    margin: 0px;
  }
`
const PriceHeader = styled.div`
  color: ${props => props.theme.color.secondary.regular};
  font-size: 22px;
  font-weight: 700;
  padding-left: 50px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 5px;
`
const PriceModifier = styled.span`
  color: ${props => props.theme.color.secondary.regular};
  font-size: 15px;
  font-weight: 400;
  padding-left: 2px;
`

const ListItem = styled.div`
  padding-left: 50px;
  padding-right: 20px;
  padding-bottom: 12px;
  padding-top: 12px;
`

const ButtonItem = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  margin: auto;
`

const TryItButton = styled.button`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 50px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const PricingTitle = styled.h1`
  margin: 0 auto 60px;
  text-align: center;
  color: ${props => props.theme.color.primary};
  font: 700 28px ${props => props.theme.font.primary}, sans-serif;
`
