import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'

import { Container } from "../global"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "laptop_showcase" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="features">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              <FormattedMessage id="features_headline" defaultMessage="Everything you need in a modern equipment management and tracking system" />
            </h1>
            <h2>
              <FormattedMessage id="features_text" defaultMessage="Buildsight is offering a range of features providing everything needed for a modern tool tracking and equipment management process." />
            </h2>
            <ul style={{ listStyleType: "none",   margin: 0, padding: 0 }}>
              <li style={{ paddingBottom: "14px" }}>
                <FontAwesomeIcon style={{ color: "#EACC47" }} icon={faCheckCircle} size="1x" />
                <FeatureListText>
                  <FormattedMessage id="feature_text_1" defaultMessage="Tool inventory, document and save all your tools" />
                </FeatureListText>
              </li>
              <li style={{ paddingBottom: "14px" }}>
                <FontAwesomeIcon style={{ color: "#EACC47" }} icon={faCheckCircle} size="1x" />
                <FeatureListText>
                  <FormattedMessage id="feature_text_2" defaultMessage="Checkout and return tools, keep track of location and usage" />
                </FeatureListText>
              </li>
              <li style={{ paddingBottom: "14px" }}>
                <FontAwesomeIcon style={{ color: "#EACC47" }} icon={faCheckCircle} size="1x" />
                <FeatureListText>
                  <FormattedMessage id="feature_text_3" defaultMessage="Full user management and user roles" />
                </FeatureListText>
              </li>
            </ul>

          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Features

const HeaderWrapper = styled.header`
  background-color: #F9FAFB;
  padding: 60px 20px 40px 20px;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const FeatureListText = styled.span`
  color: ${props => props.theme.color.accent};
  font: 400 16px ${props => props.theme.font.secondary}, sans-serif;
  padding-left: 30px
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
