import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

import Slider, { SliderTooltip } from 'rc-slider';

import { Container } from "../global"

// Ip
import ipLocation from "iplocation"
import publicIp from "public-ip";



const Header = () => {

  const intl = useIntl();

  const [rate, setRate] = useState("495");
  const [locale, setLocale] = useState("sv-SE");
  const [currency, setCurrency] = useState("SEK");


  const [savings, setSavings] = useState(
    getSavingCurrency(
      intl.formatMessage({ id: "savings_currency", defaultMessage: "SEK" }),
      intl.formatMessage({ id: "savings_locale", defaultMessage: "sv-SE" }),
      Number(intl.formatMessage({ id: "savings_default", defaultMessage: "198000" })),
    )
  )

  const [sliderValue, setSliderValue] = useState(5);

  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "construction_people" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  function calculateSavings(amount) {

    let tempSavings = rate * 10 * Math.round(amount / 5) * 40

    let savingsCurrency = getSavingCurrency(
      currency,
      locale,
      tempSavings)

    setSavings(savingsCurrency)
  }

  function getSavingCurrency(currency, locale, savings) {
    return (savings).toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  const getClientIp = async () => await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"]
  });

  const getLocation = async () => {
    const ip = await getClientIp()
    const location = await ipLocation(ip);
    if (location && "country" in location) {
      if (location.country.code === "US") {
        setRate("49")
        setLocale("en-US")
        setCurrency("USD")

        setSavings(getSavingCurrency("USD", "en-US", 19600))

      } else {
        setRate("495")
        setLocale("sv-SE")
        setCurrency("SEK")

        setSavings(getSavingCurrency("SEK", "sv-SE", 198000))

      }
    }
  }

  // This is for SSR in Gatsby and the dynamic stuff happening on client side vs compile time.
  React.useEffect(() => {
    getLocation();
  }, []);

  const { Handle } = Slider;

  const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        overlayInnerStyle={{ paddingLeft: "10px", paddingRight: "10px", height: "26px", backgroundColor: '#173161', color: "#FFF" }}
        prefixCls="rc-slider-tooltip"
        overlay={`${value} ${intl.formatMessage({ id: "savings_employees", defaultMessage: "employees" })}`}
        visible={true}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              <FormattedMessage id="main_headline_1" defaultMessage="Tool tracking for construction companies." />
              <br />
              <FormattedMessage id="main_headline_2" defaultMessage="Reduce tool budget by up to 20%" />
            </h1>
            <h2>
              <FormattedMessage id="main_text" defaultMessage="The buildsight app is a modern solution to keep track of all your equipment and tools." />
            </h2>
            <h2>
              <FormattedMessage id="savings_headline" defaultMessage="Calculate how much you could save annually" />
            </h2>
            <div>
              <UserSliderContainer>
                <Slider
                  trackStyle={{ backgroundColor: '#173161', height: 10 }}
                  handleStyle={{
                    borderColor: 'white',
                    marginLeft: -5,
                    marginTop: -7,
                    height: 24,
                    width: 24,
                    backgroundColor: '#173161',
                  }}
                  railStyle={{ height: 10 }}
                  handle={handle}
                  onChange={(value) => {
                    setSliderValue(value)
                    calculateSavings(value)
                  }}
                  min={5}
                  max={200}
                />
              </UserSliderContainer>
            </div>

            <h2>
              <FormattedMessage id="savings_text" defaultMessage="Save up to " /> <b>{savings}</b> <FormattedMessage id="savings_annually" defaultMessage=" / anually" />
            </h2>
            {/* <HeaderForm> */}
            {/* <HeaderInput onChange={(e) => calculateSavings(e.target.value)} placeholder="Antal hantverkare" /> */}

            {/* <h2>
                {savings} kr / månad
              </h2> */}
            {/* </HeaderForm> */}
            <HeaderButton
              onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://app.buildsight.se/signup';
              }} >
              <FormattedMessage id="header_get_started_button" defaultMessage="Get started" />
            </HeaderButton>
            <CenteredText>
              <FormattedMessage id="pricing_plan_medium_get_started" defaultMessage="Get started" />
            </CenteredText>
          </HeaderTextGroup>

          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.white.lessdark};
  padding: 180px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const UserSliderContainer = styled.div`
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  > div {
    width: 100%;
    margin-bottom: -4.5%;
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 650 36px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 3fr 4fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  display: block;
  margin-left: 0px;
  margin-bottom: 20px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  background: ${props => props.theme.color.secondary};
  border-radius: 10px;
  padding: 8px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`


const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 40%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  margin-right: 10px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const CenteredText = styled.p`
  text-align: center;
  margin-top: 0px;
`

const StyledImage = styled(Img)`
  width: 600px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
