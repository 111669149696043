import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const GetStarted = () => (
  <StyledSection id="contact">
    <GetStartedContainer>
      <GetStartedTitle><FormattedMessage id="get_started_headline" defaultMessage="Get started today" /></GetStartedTitle>
      <p>
        <FormattedMessage id="get_started_text" defaultMessage="If you have any more questions visit our FAQ or reach out to us! We would love to grab a coffee and show you the system and how much money time and hassle it can save you." />
      </p>
      <TryItButton
        onClick={(e) => {
          e.preventDefault();
          window.location.href = 'https://app.buildsight.se/signup';
        }}>
        <FormattedMessage id="get_started_button" defaultMessage="Get started" />
      </TryItButton>
      <CenteredText>
        <FormattedMessage id="pricing_plan_medium_get_started" defaultMessage="Get started" />
      </CenteredText>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 0, 100% 93%, 0 76%);
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 60px 20px 40px 20px;
  }
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
  color: ${props => props.theme.color.primary};
  font: 700 40px ${props => props.theme.font.primary}, sans-serif;
`

const TryItButton = styled.button`
  font-family: ${props => props.theme.font.normal};
  ${props => props.theme.font_size.xsmall};
  color: white;
  border-radius: 10px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  display: block;
  margin-left: 0px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const CenteredText = styled.p`
  text-align: center;
`