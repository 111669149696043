import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const ProductRight = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "person_computer_rocket" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <ProductRightWrapper id="top">
      <Container>
        <Flex>
          <ProductRightTextGroup>
            <h1>
              <FormattedMessage id="product_right_headline" defaultMessage="Reduce losses and tools going missing." />
            </h1>
            <h2>
              <FormattedMessage id="product_right_text" defaultMessage="Flag tools for service, always know who is responsible for a tool or piece of equipment and follow up easily." />
            </h2>
          </ProductRightTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </ProductRightWrapper>
  )
}

export default ProductRight

const ProductRightWrapper = styled.header`
  background-color: #F9FAFB;
  padding: 40px 0 40px 0;
  position: relative;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 60px 20px 40px 20px;

  }
`

const ProductRightTextGroup = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 700 28px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 400 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
