import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Container } from "../global"
import { FormattedMessage } from "gatsby-plugin-react-intl"

const Testimonials = () => {
    const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "alexandervvs_logo" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

    return (
        <TestimonialsWrapper id="product">
            <Container>
                <Flex>
                    <HeaderTextGroup >
                        <h3>
                            <FormattedMessage id="testimonials_text" defaultMessage="Trusted by world class companies" />
                        </h3>
                    </HeaderTextGroup>
                </Flex>
                <Flex>
                    <StyledImage fluid={data.file.childImageSharp.fluid} />
                </Flex>
            </Container>
        </TestimonialsWrapper>
    )
}

export default Testimonials

const TestimonialsWrapper = styled.header`
  background-color: #F9FAFB;
  padding: 100px 0 0 0;
  position: relative;
  clip-path: polygon(0 0, 100% 24%, 100% 100%, 0% 100%);
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`



const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 700 40px ${props => props.theme.font.primary}, sans-serif;
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.accent};
    font: 500 18px ${props => props.theme.font.secondary}, sans-serif;
  }

  h3 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
    font: 500 16px ${props => props.theme.font.primary}, sans-serif;
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`

const StyledImage = styled(Img)`
  width: 140px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 140px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 80px;
  }
`
