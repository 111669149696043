import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import ExtendedFeatures from "../components/sections/extended-features"
import Testimonials from "../components/sections/testimonials"
import ProductLeft from "../components/sections/product-left"
import ProductRight from "../components/sections/product-right"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import Pricing from "../components/sections/pricing"
import CookieConsent from "react-cookie-consent";
import { useIntl } from "gatsby-plugin-react-intl";
import "../styles/cookie-consent.css";


const IndexPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "title" })}
        description={intl.formatMessage({ id: "description" })}
        keywords={intl.formatMessage({ id: "keywords" })}
        lang={intl.locale}
      />
      <Navigation />
      <Header />
      <Testimonials />
      <ProductLeft />
      <ProductRight />
      <Features />
      <ExtendedFeatures />
      <Pricing />
      <GetStarted />
      <Footer />
      <CookieConsent
        disableStyles={true}
        onAccept={() => {
          window.trackHotjar();
          window.trackGoogleAnalytics();
        }}
        buttonText={intl.formatMessage({ id: "cookie_consent_accept_button" })}
        cookieName="gdpr-analytics-enabled"
        containerClasses="bottom-wrapper root-style container"
        buttonClasses="consent-button"
        contentClasses="consent-content"
        expires={150}
      >
        {intl.formatMessage({ id: "cookie" })} <br /><a style={{color: "black"}} href="/privacy-policy">{intl.formatMessage({ id: "read_more" })} </a>
      </CookieConsent>
    </Layout>
  )
}


export default IndexPage
